.my-account-live-chat-v1 .cs-3-col-row-v1 {
  &.row {
    background: transparent;
    h2 {
      font-size: 22px !important;
      line-height: 23px;
      margin: 0 0 20px;
    }
  }
  .customer-care-list .customer-care-list-item .bottom {
    top: 155px;
  }
}

.my-account-live-chat-v1 .button.disabled {
  background: #bebebe;
}
