$color-cl-green-3: #44a07e;

.site-logo-responsive-v1 {
  a {
    @include swap_direction(padding, 0 5px);
  }
}

.trustmark {
  .trustmark_p {
    ul {
      margin-#{$ldirection}: 40px;
      list-style: disc;
    }
  }
}

.ie8 {
  #ie_version {
    .sitewide-ie-upgrade__content {
      border: 1px solid $color-light-grey;
      a.button--secondary {
        background: $color-cl-green-3;
        @include swap_direction(padding, 10px);
        margin-top: 20px;
        display: inline-block;
        color: $color-white;
        font-size: 19px;
      }
      p {
        font-size: 19px;
      }
    }
    #closeBlock {
      top: 3px !important;
      position: absolute;
      bottom: 0;
      right: 3px;
      background: url(/sites/all/modules/custom/elc_common/libraries/colorbox/images/controls.png)
        no-repeat -25px 0;
      width: 25px;
      height: 25px;
      text-indent: -9999;
    }
  }
  #overlay-background {
    background-color: $color-white;
    opacity: 0.95;
    filter: alpha(opacity=95);
  }
}

#gnav_signin {
  .gnav-loyalty-join-block {
    display: none !important;
  }
}

#signin-block {
  h2.myaccount-hdr {
    width: 100%;
    font-size: 33px;
    line-height: 33px;
  }
}

.three-step-formatter-v1 {
  .outer-wrap {
    .three-step-formatter-header {
      .title {
        white-space: normal;
        line-height: 1.5;
      }
    }
  }
}

.three-step-sticky-header {
  .cols {
    line-height: 20px;
    .title {
      white-space: normal;
    }
  }
}

.section-customer-service {
  .field-collection-slides {
    .basic-responsive-v1 {
      width: 950px;
      margin: 80px auto 50px auto;
    }
  }
}

.customer-care-my-order {
  h2 {
    line-height: 50px;
  }
}

.wrapper {
  #navigation {
    .menu-item-blocks {
      .outer-wrap {
        .mm_3col_1tout_v1 {
          .col {
            h2 {
              font-weight: bold;
            }
            ul {
              a {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
  .container {
    .field-elc-mpp-content {
      .mpp-wrapper {
        .mpp_cat_header {
          line-height: 1.5;
        }
      }
    }
  }
  #BVSubmissionContainer {
    .BVPageEditReview {
      min-height: 1800px;
    }
  }
}

.wrapper {
  .popup-offer-v1 {
    .form-submit-wrap {
      text-align: center;
      .button[type='submit'] {
        vertical-align: top;
        line-height: 26px;
      }
    }
    .popup-offer-footer-msg {
      font-size: 9px;
    }
    .signup {
      #{$ldirection}: 33.3%;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .wrapper {
    .popup-offer-v1 {
      .signup {
        #{$ldirection}: 29%;
      }
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_DESKTOP_Thai.jpg');
}
/* MASCARA COLLECTION CSS */
.section-mascara-collection {
  .mpp-product-group-v1 {
    .outer-wrap {
      max-width: 1100px;
      padding-left: 55px;
    }
    .mascara-product-v1 {
      .row {
        margin-bottom: 40px;
        margin-top: 40px;
        min-height: 450px;
        position: relative;
      }
      .product-image {
        margin-right: 40px;
        margin-top: 5px;
      }
      .mascara-title {
        padding-left: 10px;
      }
      .mascara-subtitle {
        padding-left: 10px;
      }
      .mascara-icon {
        position: absolute;
        top: 130px;
        margin: 0 0 10px 5px;
      }
      .mascara-icon img {
        max-width: 140px;
      }
      .product-info {
        padding-left: 10px;
        padding-right: 45%;
        position: absolute;
        top: 310px;
      }
      .caret {
        margin: 27px 0 0 0;
      }
    }
  }
}

.content {
  .gnav-header-formatter {
    &__bottom {
      position: relative;
    }
  }
}
