/* -----Power Reviews------ */

#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'เป็นประโยชน์';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'ไม่มีประโยชน์นัก';
              }
            }
          }
          .pr-rd-flag-review-container {
            width: auto;
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &:before {
            content: 'กรองตาม:';
          }
        }
        .pr-rd-main-header-search-sort {
          width: 49%;
        }
        .pr-rd-review-header-sorts {
          width: 61%;
          .pr-rd-sort-group {
            float: right;
          }
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 90% 6px transparent;
          width: 36%;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: 'จัดเรียงตาม:';
          }
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: 'จัดเรียงตาม:';
        }
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .tag-group {
      .pr-label-control {
        white-space: initial;
      }
    }
  }
}

.mpp-grid {
  .reviews {
    .p-w-r {
      .pr-snippet {
        .pr-category-snippet__rating,
        .pr-category-snippet__total,
        .pr-snippet-stars-container,
        .pr-snippet-read-and-write {
          display: block;
          margin-top: 0;
        }
        .pr-snippet-stars-container {
          float: none;
        }
      }
    }
  }
}
