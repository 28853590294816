#booking-step3 {
  .book-appt-container {
    .mobile-phone-fieldset {
      float: right;
      padding: 0;
      border: 0;
      .appt-book-mobile-prefix {
        width: 20%;
        display: inline-block;
      }
      .appt-book-mobile {
        width: 80%;
      }
    }
    .registration__terms,
    .registration__commercial-list,
    .registration__email-list {
      &.error {
        color: $color-notice-pink;
        a {
          color: $color-notice-pink;
        }
      }
    }
    .registration__commercial-list {
      font-family: $base-font-family;
      font-size: 13px;
      width: 48.2%;
      float: left;
      input {
        width: 20px;
        height: 20px;
        float: left;
        margin-bottom: 100px;
        @include breakpoint($ab-small-down) {
          width: 15px;
          height: 15px;
          margin-right: 5px;
          margin-bottom: 17px;
        }
      }
    }
  }
  input[type='checkbox'] {
    margin-right: 8px;
  }
}

.appt-book {
  .service-select {
    .service {
      min-height: 500px;
      .service-details {
        min-height: 200px;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .confirm-container {
        .total-time {
          width: 50%;
        }
        .appt-book-book-submit {
          width: 50%;
        }
      }
      .sign-in-container {
        .social-login-appointment {
          line-height: 18px;
        }
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content {
      position: absolute;
    }
  }
}

#appointments {
  #appointment-book-container {
    .appt-book-page-header-content {
      margin-left: 200px;
    }
  }
}
