.store-locator-v1 {
  position: relative;
}

.store-locator-v1 fieldset#store-search-controls {
  border: none;
  margin: 0;
}

.store-locator-v1 .map-marker {
  background-image: url($template_base_path + '/store_locator_v1/result-pin.png');
  width: 29px;
  height: 44px;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  font-weight: bold;
}

.store-locator-v1 .map-marker:hover {
  cursor: pointer;
}

.store-locator-v1 .button,
.store-locator-popup-v1 .button {
  padding-top: 3px;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
}

.store-locator-popup-v1 .event-popup-header {
  margin-bottom: 7px;
}

.store-locator-popup-v1 .event-popup-content {
  font-size: 15px;
  margin: 7px 0 10px;
  min-height: 125px;
}

.overlay-container.store-locator-popup-v1 .close-container .close-link {
  width: 12px;
  height: 11px;
  background-size: 12px 11px;
}

.store-locator-popup-v1 .event-large-image {
  float: #{$rdirection};
}

.store-locator-v1 .more-results-container {
  text-align: center;
  margin: 10px 0;
}

.store-locator-v1 .geo_container {
  position: relative;
}

.store-locator-v1 .geo_container .button .geo_icon {
  position: relative;
  top: 3px;
  #{$ldirection}: -3px;
  margin-#{$rdirection}: 6px;
}

.store-search-controls {
  padding: 20px 0;
}

.store-search-controls input[type='text'] {
  font-size: 12px;
  font-family: $base-font-family-roman;
  color: black;
  height: 28px;
  letter-spacing: 1px;
}

.store-search-controls input.search {
  width: 200px;
}

.store-search-controls {
  .state_container,
  .city_container {
    margin-#{$rdirection}: 10px;
  }
}

.store-search-controls .country_container {
  margin: 0 25px;
  padding-top: 1px;
}

.store-locator-v1 .sbHolder {
  width: 200px;
}

.store-locator-v1 .sbSelector {
  margin-#{$ldirection}: 0;
}

.store-locator-v1 .sbOptions li {
  padding-#{$ldirection}: 0;
}

.store-locator-v1 .sbOptions {
  overflow-y: auto !important;
  max-height: 194px !important;
}

.store-locator-header {
  width: 100%;
  background: url($template_base_path + '/store_locator_v1/locator-header-bg.jpg') 0 bottom repeat-x;
  padding: 20px 0;
  font-size: 18px;
  font-family: $base-font-family;
  border-bottom: 1px solid #dadada;
}
/* TODO: Remove all toggle stuff at the end of proj. */
.store-locator-toggle-container {
  font-size: 15px;
  font-family: $base-font-family;
}

.store-locator-header .store-locator-header-title {
  margin-bottom: 0;
}

.store-locator-toggle-container {
  margin: 10px 0 20px;
}

.store-locator-v1.stores-active .store-locator-header-to-stores-toggle {
  display: none;
}

.store-locator-v1.tr-active .store-locator-header-to-tr-toggle {
  display: none;
}

.store-locator-v1 span.store_name,
.store-locator-v1 .result_number {
  font-size: 15px;
  font-family: $base-font-family;
}

.store-locator-v1 .result_number {
  float: #{$ldirection};
  height: 80px;
  margin-#{$rdirection}: 8px;
  display: block;
  text-align: #{$rdirection};
  color: #000;
}

.store-locator-v1 .store_name {
  display: block;
  margin-bottom: 8px;
}

.store-locator-v1 .store_name a {
  color: #000;
}

.store-locator-v1 .event-image {
  float: #{$ldirection};
  margin-#{$rdirection}: 15px;
}

.store-locator-v1 .menu {
  position: absolute;
  top: 503px;
}

.store-locator-v1 .menu {
  background: url($img_base_path + '/trans-333333-60.png') repeat scroll 0 0 transparent;
  #{$ldirection}: 0;
  margin: 10px 0 0;
  height: 36px;
  padding: 12px 0;
  position: absolute;
  width: 100%;
  z-index: 99;
  text-align: center;
}

.store-locator-v1 .menu li {
  text-align: center;
  font-size: 11px;
  padding: 0 12px 11px;
  cursor: pointer;
  display: inline-block;
  padding: 0 12px;
}

.store-locator-v1 .menu li a {
  color: #ffffff;
  text-transform: uppercase;
}

.store-locator-v1 .menu li a.active {
  text-decoration: underline;
}

.store-locator-v1 .menu .menu-mail-order {
  display: none;
}

@media only screen and (device-width: 768px) {
  .store-search-controls .country_container {
    margin: 0 15px;
  }
  .store-search-controls .search_alternative_text {
    margin: 3px 10px 0;
  }
  .store-search-controls .search_container input.search {
    width: 150px;
  }
  .store-locator-v1 .store-search-controls .sbHolder {
    width: 150px;
  }
}
/* LOCATOR
------------------------------ */

div.store-locator-body {
  background: #fff;
  margin: 0 auto;
  position: relative;
  /* max-width: 860px; */
  padding: 0;
}

.location_info {
  position: static;
}

.location_info img {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
}

.info_content {
  text-align: #{$ldirection};
}

.info_content h1 {
  color: #5dba98;
  font-size: 18px;
  line-height: normal;
  margin: 8px 0 17px;
}

.info_content .phone_no {
  margin-bottom: 10px;
}

.gm-style-iw,
.gm-style-iw > div {
  overflow: hidden !important;
  height: auto !important;
  width: auto !important;
}

.gmnoprint div[title^='Pan'] {
  opacity: 0 !important;
}

.info_content a {
  @include swap_direction(padding, 0 5px 10px 0);
  text-decoration: underline;
  display: block;
  float: #{$ldirection};
}

.info_content input[type='text'] {
  width: 130px;
  padding: 3px 10px;
  margin-#{$rdirection}: 10px;
}

.info_content input[type='submit'] {
  color: #fff;
  padding: 3px 10px;
}

.hide,
.neither {
  display: none;
}

.local_country_container.state {
  display: none;
}

.state_container.country {
  display: none;
}

.search_section_container .zip_container {
  width: 20%;
}

.search_section_container .zip_container input {
  width: 90%;
}

.address_section_container .form_element {
  width: 20%;
}

.form_element.zip_container label {
  margin: 0 0 10px;
  display: block;
}

.address_section_container .form_element.distance_container label {
  text-align: #{$ldirection};
  width: 90%;
  display: block;
  padding: 0 0 0 10%;
  margin: 0 0 10px;
}

.address_section_container .form_element select {
  width: 10%;
}
/* PANELS
-------------------- */
.store-locator-body #locator_directions_panel {
  display: none;
}

.store-locator-body .locations_form_panel,
.locations_map_panel {
  float: #{$ldirection};
  width: 100%;
}

.landing_promos {
  float: #{$ldirection};
  @include swap_direction(padding, 0 20px 0 20px);
  width: 460px;
}

.locations_table_panel {
  clear: both;
}
/* MAP
--------------------- */
div.map {
  margin-bottom: 50px;
  height: 430px;
  display: none;
}
/* SEARCH FORM
--------------------- */
div.store-locator-body h3,
div.store-locator-body h4,
div.directions_form h4,
fieldset.locator div.form_element label {
  font-size: 11px;
  font-family: $base-font-family-roman;
  color: #010101;
  border-top: solid 1px #ccc;
  border-bottom: none;
  font-family: inherit;
  text-transform: uppercase;
}

div.store-locator-body h3.store_locator {
  font-size: 11px;
  font-family: $base-font-family-roman;
  font-weight: normal;
  cursor: pointer;
  font-variant: normal;
  text-transform: uppercase;
}

div.store-locator-body h3.linked {
  border-bottom: 0;
}

div.store-locator-body h3 a {
  text-decoration: none;
}

div.store-locator-body fieldset.locator div.form_element {
  float: #{$ldirection};
}

div.store-locator-body fieldset.intl_search div.form_element {
  text-align: #{$ldirection};
}

fieldset.locator div.form_element label {
  border: none;
}

.store-locator-body fieldset.fs div.form_element input,
.store-locator-body fieldset.fs div.form_element select,
.directions-left fieldset.fs div.form_element input,
.directions-left fieldset.fs div.form_element select {
  float: none;
  border: solid 1px #aaa;
}

.store-locator-body fieldset.fs div.form_element input,
.store-locator-body fieldset.fs div.form_element select {
  float: #{$ldirection};
}

.search_alternative_text {
  float: #{$ldirection};
  line-height: 22px;
  text-align: center;
  margin: 3px 30px 0;
  text-transform: uppercase;
}
/* fieldset.locator a.search_button,
fieldset.locator a.directions_submit,
.store_directions .search_button,
a.close_directions,
a.directions_button {
  background: none repeat scroll 0 0 black;
  border: solid 1px #ccc;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 10px;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  float: #{$rdirection};
  *padding: 1px 10px;
} */

a.directions_button {
  float: none;
  margin: 10px auto 15px;
}

fieldset.locator a.directions_submit {
  float: none;
  vertical-align: top;
}

.store_directions .search_button {
  float: #{$ldirection};
  clear: both;
  display: block;
}

.accord_state {
  font-size: 16px;
  font-family: $base-font-family;
}
/* STORE RESULTS
----------------------- */
.results-count {
  font-weight: 900;
  letter-spacing: 0.5px;
  display: none;
  font-size: 17px;
  font-family: $base-font-family;
  padding: 0 0 15px;
  margin: 0;
}

.results-count .query {
  color: #000;
}

table.doors_results {
  border-collapse: collapse;
  border: none;
  border-bottom: 1px solid #aaa;
  width: 100%;
}

table.doors_results th {
  font-size: 12px;
  font-family: $base-font-family-roman;
  text-transform: uppercase;
  font-weight: normal;
  padding-bottom: 10px;
}

table.doors_results th.ttl_store {
  /* width: 104px; */
  background: url('/media/images/locator/store.gif') no-repeat 0 22px;
}

table.doors_results th.ttl_event {
  /* width: 104px; */
  background: url('/media/images/locator/event.gif') no-repeat 0 22px;
}

table.doors_results th.ttl_distance {
  /* width: 104px; */
  background: url('/media/images/locator/distance.gif') no-repeat 0 22px;
}

table.doors_results tbody {
  border: none;
  border-width: 0;
}

table.doors_results td {
  font-size: 12px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
  border-width: 1px 0 0 0;
  position: relative;
  z-index: 999;
}

div.locations_form_panel a.close_directions {
  display: block;
  margin: 20px auto 0;
  width: 185px;
  float: none;
}

#locator_directions_panel {
  margin: 0;
}

h2.directions-header {
  font-size: 13px;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.directions_panel h2 {
  clear: both;
}

.directions_results {
  width: 600px;
  margin: 0 auto;
  border-top: 1px solid #ccc;
}

.directions-actions {
  text-align: #{$rdirection};
  width: 600px;
  margin: 0 auto -25px;
}

.directions-actions a {
  display: inline-block;
  margin-#{$ldirection}: 25px;
  text-transform: uppercase;
}

.destination_container,
.directions_form fieldset {
  width: 50%;
  float: #{$ldirection};
  padding-#{$ldirection}: 130px;
}

.destination_container {
  padding-#{$ldirection}: 10px;
  margin: 10px 0;
}

.destination_container .destination_address_container,
.destination_container .destination_address_container img {
  float: #{$ldirection};
}

.destination_container .destination div {
  margin: 0 0 4px;
}

.destination_container .destination {
  margin: 0 0 10px;
  padding-#{$ldirection}: 35px;
}

.directions_map_container {
  height: 330px;
}

table.adp-placemark,
table.adp-placemark td {
  border: none;
  background-color: #fff;
}

table.adp-directions {
  border-#{$ldirection}: none;
  width: 90%;
  margin: 0 0 0 10%;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}

table.adp-directions td {
  border-#{$rdirection}: none;
}

.adp-step,
.adp-substep {
  border: none;
}

.classic img.adp-marker {
  display: none;
}

.adp-placemark .adp-text {
  background: url($img_base_path + '/locator/map-marker-B.png') no-repeat 0 0;
  padding-#{$ldirection}: 30px;
  height: 40px;
}

#adp-placemark .adp-text {
  background: url($img_base_path + '/locator/map-marker-A.png') no-repeat 0 0;
}

.adp-summary {
  text-align: #{$rdirection};
  padding-bottom: 10px;
}

.adp-step,
.adp-substep {
  padding: 6px 3px;
}
/* E-MAIL SIGN-UP
------------------------- */
fieldset.email {
  width: 460px;
}

fieldset.email div.form_element {
  clear: both;
}

fieldset.email div.submit_container {
  text-align: #{$rdirection};
  width: 279px;
}

fieldset.email div.submit_container input.submit {
  border: 0;
  display: inline;
  width: 79px;
  height: 18px;
}

.directions_submit {
  color: #ed1c24;
  font-weight: bold;
  text-decoration: none;
}

.directions_submit:hover {
  text-decoration: underline;
}

.store-locator-body ul.error_messages {
  margin: 0;
  li {
    color: $color-notice-pink;
    margin: 5px 0 15px;
    &.inactive {
      display: none;
    }
  }
}

.section-header {
  background: none;
  font-size: 22px;
  line-height: 39px;
  margin: 0;
  text-align: #{$ldirection};
  color: #46ab8c;
}

.section-header.active a {
  background-position: right -77px;
  cursor: default;
}

.section-header.active a:hover {
  text-decoration: none;
}

.section-header a {
  background: url('/media/export/images/accordion-ui-controls2.png?1372119180') no-repeat scroll right 15px #000000;
  color: #ffffff;
  display: inline-block;
  height: 37px;
  line-height: 39px;
  padding-#{$rdirection}: 30px;
}
/* detail bottom copy */
.detail_copy_section {
  background: url('/media/export/images/hr-double-border.png?1366128733') repeat-x scroll 0 top transparent;
  padding: 35px 150px 50px;
  text-align: center;
  margin: 30px 0;
}

.panel_3 .events_img01 {
  display: block;
  padding-bottom: 25px;
}

.panel_3 .events_img02 {
  display: block;
  padding-top: 25px;
}

@media screen and (max-width: 1024px) {
  .touch .address_section_container .form_element select {
    width: 194px !important;
  }
}

@media (max-width: 859px) {
  .locator_content {
    min-width: 768px;
    width: auto !important;
    width: 768px;
  }
  .touch .address_section_container .form_element select {
    width: 173px !important;
  }
}
/* ADD EVENTS TO CALENDAR */

#store_results .event-details a.addthisevent-drop {
  display: inline-block;
  position: relative;
  z-index: 0 !important;
  text-decoration: none;
  background: none;
  border: 0;
  color: #5dba98 !important;
  font-family: $base-font-family;
  font-size: 12px;
  font-weight: 300;
  padding: 0;
}

#store_results .event-details a {
  margin: 5px 0;
}

.overlay-container.store-locator-popup-v1 a.addthisevent-drop {
  background-color: #5dba98;
  background-image: none;
  border: 0 none;
  color: #ffffff !important;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 1.5;
  padding: 3px 10px 3px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.overlay-container.store-locator-popup-v1 a.addthisevent-drop:hover {
  background: none repeat scroll 0 0 #44a07e;
  text-decoration: none;
}
/* END ADD EVENTS TO CALENDAR */

/* APPOINTMENT BOOKING */

.door_row .store-locator_book-appointment {
  border: 1px solid #9e6f9c;
  display: block;
  font-size: 12.45px;
  height: 42px;
  letter-spacing: -0.02em;
  line-height: 1;
  margin: 5px 0;
  padding: 9px 10px;
  text-transform: uppercase;
  width: 230px;
  color: #9e6f9c;
  font-family: $base-font-family-roman;
  .icon {
    background: $color-white url('/media/export/cms/appointment_booking/icon-calendar.jpg') no-repeat scroll 0 0;
    width: 28px;
    height: 23px;
    float: left;
  }
  &:hover {
    color: #9e6f9c;
  }
}

.info_content {
  padding-bottom: 50px;
  width: 250px;
  padding-left: 15px;
  .store-locator_book-appointment {
    bottom: 0;
    color: $color-white;
    height: 42px;
    left: 0;
    letter-spacing: -0.02em;
    line-height: 1;
    padding: 9px 10px;
    position: absolute;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    background-color: #9e6f9c;
    &:hover {
      color: $color-white;
    }
    .icon {
      background: #9e6f9c url('/media/export/cms/appointment_booking/icon-calendar-purple.jpg') no-repeat scroll 0 0;
      width: 28px;
      height: 23px;
      float: left;
    }
  }
}
/* END APPOINTMENT BOOKING */
