$address-border: #999999;
$address-background-color: #eeeeee;
$label-error: #ef6ea8;
$disabled-color: #dddddd;

.address-search-results {
  z-index: 999;
  border: 1px solid $address-border;
  background-color: $address-background-color;
  width: 97%;
  height: 200px;
  overflow: scroll;
  font-size: 12px;
}

label.error {
  color: $label-error;
}

.passwordreset {
  padding-top: 50px;
}

.bottom-margin {
  margin-bottom: 15px;
}

textarea.error {
  border: 1px solid $label-error;
}

.checkout-btn {
  .button.disabled {
    background: $disabled-color;
    cursor: not-allowed;
  }
}

html {
  #header {
    @media #{$medium-only} {
      .branding {
        width: 33%;
      }
    }
    .branding {
      .site-logo-responsive-v1 {
        a.left_logo-link {
          float: left;
        }
        a.right_logo-link {
          float: right;
          img {
            width: 62px;
          }
        }
        @media #{$medium-only} {
          a {
            padding: 0 8px;
          }
        }
      }
    }
    .top-right {
      .block:last-child {
        padding-top: 6px;
      }
      .block-template-offer-promo-v1 {
        .offer-promo-v1 {
          .headline {
            span {
              font-size: 12px;
            }
          }
        }
      }
      .block-template-gnav-search-v1 {
        .gnav-search-v1 {
          #search {
            line-height: 40px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.lpPoweredBy {
  display: none;
}

.content {
  .header-nav-formatter-v1 {
    .shortcuts {
      .header-nav-row-v1 {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
        .lpStaticButtonTR {
          a {
            padding: 0 0;
          }
        }
      }
      li.header-nav-row-v1.live-chat a {
        border: none !important;
        background: none !important;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      li.header-nav-row-v1.live-chat a img {
        border: none !important;
        background: none !important;
        margin-left: 5px;
        padding: 0;
        width: auto;
        height: 17px;
      }
    }
  }
}

.tout-image-v1 {
  &.tout {
    .tout-info {
      .body {
        p.fragrance_text {
          line-height: 1;
          margin-bottom: 10px;
        }
      }
      .link {
        a.gnav_tout_cta {
          color: $color-white;
        }
      }
    }
  }
}

.section-diagnostics {
  .mascara-module-formatter-header {
    .mascara-menu {
      .item.item-8,
      .item.item-9 {
        display: none;
      }
    }
  }
}

.footer-top-v1 .social-links {
  display: inline-block;
  vertical-align: top;
  width: 12%;
}

.footer-top-v1 .signup-forms {
  display: inline-block;
  vertical-align: top;
  width: 72%;
}

.footer-top-v1 .newsletter-signup,
.footer-top-v1 .mobile-signup {
  display: inline-block;
  margin: 0;
  width: 72%;
  vertical-align: top;
}

.footer-top-v1 .tel {
  width: 15%;
}

.footer-top-v1 {
  .footer-kbank-logo,
  .footer-dbd-logo {
    display: inline-block;
    margin: 0;
    vertical-align: top;
  }
  .footer-kbank-logo img,
  .footer-dbd-logo img {
    width: 90px;
    height: 40px;
  }
}

#shipping {
  #main {
    .checkout-wrapper {
      .shipping-address-option {
        .address_controls {
          margin-left: 6px;
        }
      }
    }
  }
}

.gnav-offer-image,
.gnav-offer-header {
  display: inline-block;
}

.single-video-v1 {
  .video_player {
    video {
      max-height: 100% !important;
    }
  }
}
